.card-list {
  $gutter: 70px;

  display: block;
  overflow: hidden;
  padding: 32px 0;

  @media (min-width: 992px) {
    padding: 110px 0;
  }

  &__inner {
    display: block;
    max-width: 1640px + ($site-wide-padding * 2);
    margin: 0 auto;
    overflow: visible;
    padding-left: $site-wide-padding;
    padding-right: $site-wide-padding;
    width: 100%;
  }

  &__items {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: ($gutter / 2) * -1;
    margin-right: ($gutter / 2) * -1;

    > * {
      flex: 0 1 calc(#{percentage(1 / 1)} - #{$gutter});
      margin-bottom: 32px;
      margin-left: ($gutter / 2);
      margin-right: ($gutter / 2);
      max-width: 500px;

      @media (min-width: 992px) {
        flex: 0 1 calc(#{percentage(1 / 2)} - #{$gutter});
        margin-bottom: 110px;
      }

      @media (min-width: 1366px) {
        flex: 0 1 calc(#{percentage(1 / 3)} - #{$gutter});
      }
    }
  }
}

.ar-card {
  display: flex;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  color: $brand-grey-dark;
  flex-direction: column;
  transition: box-shadow 0.15s ease-out, transform 0.15s ease-out;
  will-change: transform;

  &__media {
    position: relative;
    display: block;
    flex: 0 0 auto;
    overflow: hidden;
    padding-bottom: percentage(10 / 16);

    img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover; object-position: center center;';
      transition: transform 1s ease-out;
      width: 100%;
      will-change: transform;
    }
  }

  &__main {
    display: block;
    flex: 1 1 auto;
    padding: 16px 16px 24px;

    @media (min-width: 768px) {
      padding: 24px 24px 32px;
    }
  }

  &__title {
    display: block;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.024em;
    line-height: 24px;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__content {
    display: block;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.024em;
    line-height: 24px;
    margin: 8px 0 0;

    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 40px;
      margin: 16px 0 0;
    }
  }

  &:focus {
    outline: none;
  }

  @mixin ar-card-active {
    @media screen and (prefers-reduced-motion: no-preference) {
      box-shadow: 0px 14px 23px rgba(0, 0, 0, 0.1);
      transform: translateY(-8px);
    }

    .ar-card__title {
      text-decoration: underline;
    }

    .ar-card__media img {
      @media screen and (prefers-reduced-motion: no-preference) {
        transform: scale(1.05);
      }
    }
  }

  &:focus-within {
    @include ar-card-active;
  }

  &:hover {
    @include ar-card-active;
  }
}

.report-page {
  .ar-card {
    @media (max-width: 991px) {
      @include fade-up(0ms, 20px, self);

      .ar-card__media {
        @include fade-up(100ms, 20px, self);
      }

      .ar-card__title {
        @include fade-up(200ms, 20px, self);
      }

      .ar-card__content {
        @include fade-up(300ms, 20px, self);
      }
    }

    @media (min-width: 992px) and (max-width: 1365px) {
      &:nth-child(2n + 1) {
        @include fade-up(0ms, 20px, self);

        .ar-card__media {
          @include fade-up(100ms, 20px, self);
        }

        .ar-card__title {
          @include fade-up(200ms, 20px, self);
        }

        .ar-card__content {
          @include fade-up(300ms, 20px, self);
        }
      }

      &:nth-child(2n) {
        @include fade-up(100ms, 20px, self);

        .ar-card__media {
          @include fade-up(200ms, 20px, self);
        }

        .ar-card__title {
          @include fade-up(300ms, 20px, self);
        }

        .ar-card__content {
          @include fade-up(400ms, 20px, self);
        }
      }
    }

    @media (min-width: 1366px) {
      &:nth-child(3n + 1) {
        @include fade-up(0ms, 20px, self);

        .ar-card__media {
          @include fade-up(100ms, 20px, self);
        }

        .ar-card__title {
          @include fade-up(200ms, 20px, self);
        }

        .ar-card__content {
          @include fade-up(300ms, 20px, self);
        }
      }

      &:nth-child(3n + 2) {
        @include fade-up(100ms, 20px, self);

        .ar-card__media {
          @include fade-up(200ms, 20px, self);
        }

        .ar-card__title {
          @include fade-up(300ms, 20px, self);
        }

        .ar-card__content {
          @include fade-up(400ms, 20px, self);
        }
      }

      &:nth-child(3n) {
        @include fade-up(200ms, 20px, self);

        .ar-card__media {
          @include fade-up(300ms, 20px, self);
        }

        .ar-card__title {
          @include fade-up(400ms, 20px, self);
        }

        .ar-card__content {
          @include fade-up(500ms, 20px, self);
        }
      }
    }
  }

  .ktc-editable-area {
    .ar-card,
    .ar-card__media,
    .ar-card__title,
    .ar-card__content {
      @include animation-reset;
    }
  }
}
