.large-banner {
  position: relative;
  overflow: hidden;
}

.large-banner__media {
  height: 760px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include mq($until: mobile) {
    height: 500px;
  }

  @include mq(tablet) {
    height: 760px;
  }
}

.large-banner__overlay {
  z-index: 1;

  @include mq($until: mobile) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($brand-black, 0.3);
  }
}

.large-banner__content {
  position: absolute;
  z-index: 2;

  @include mq($until: mobile) {
    width: 100%;
    padding: 0 15px;
    bottom: 50px;
    left: 0;
  }

  @include mq(tablet) {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .btn {
    .btn__text {
      font-weight: 700;
      padding: 12px 50px;
    }
  }
}

.large-banner__title {
  color: $brand-white;
  display: block;
  background-color: $brand-primary;
  position: relative;

  @include mq($until: mobile) {
    padding: 10px 20px;
    width: 100%;
  }

  @include mq(tablet) {
    max-width: 700px;
    padding: 20px 50px 20px 0;
  }

  &:before {
    @include mq(tablet) {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      height: 110%;
      width: 80px;
      display: block;
      background-color: $brand-primary;
    }
  }

  .large-banner__content & {
    margin-bottom: 0;
  }
}

.large-banner__intro {
  padding: 0 50px 30px;
  color: $brand-white;
  display: block;
  background-color: $brand-primary;
  margin-top: -1px;

  .btn {
    margin-top: 1px;
  }

  @include mq($until: mobile) {
    padding: 10px 20px 30px;
  }

  @include mq(tablet) {
    margin-bottom: -10px;
    max-width: 650px;
    padding: 0 50px 30px;
  }
}
