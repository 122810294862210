/**
 * Add global styles in this file.
 */

html {
  color: $brand-text;
  min-height: 100%; // Ensure the page always fills at least the entire height of the viewport.
  box-sizing: border-box;

  .ContentFrame & {
    opacity: 1;
  }

  &.no-scroll {
    overflow: hidden;
    height: 100vh;
  }
}

html.wf-loading,
html.wf-active {
  opacity: 1;
}

/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  font-family: $base-font-family;
  margin: 0;
}

ul,
ol,
p {
  margin: 0 0 16px 0;
}

/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */

img {
  max-width: 100%; /* [1] */
  font-style: italic; /* [2] */
  vertical-align: middle; /* [3] */
}

hr {
  border: none;
  margin: 30px 0;
  height: 2px;
  width: 100%;
  background-color: $brand-secondary;

  .theme-2 & {
    background-color: $brand-pink;
  }
}

.lazy-load {
  opacity: 0;
  transition: opacity 1s ease;
  will-change: opacity;

  &.loaded {
    opacity: 1;
  }

  .ktc-editable-area & {
    opacity: 1 !important;
  }
}

.site-area {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  @include mq($until: desktop-small-max) {
    margin-top: 78px;
  }

  @include mq(desktop) {
    margin-top: 161px;
  }

  &--small-gap {
    @include mq($until: desktop-small-max) {
      margin-top: 0;
    }

    @include mq(desktop) {
      margin-top: 45px;
    }
  }
}

.site-content {
  flex: 1 0 auto;

  &--space {
    @include mq($until: desktop-small-max) {
      padding-top: 40px;
    }
    @include mq(tablet) {
      padding-top: 100px;
    }
  }
}

.share-icon {
  .icon-svg {
    fill: $brand-primary;
    width: 18px;
    height: 18px;
  }

  .icon-fb {
    width: 9px;
    margin-left: 4px;
  }
}

.pb {
  padding-bottom: 30px;
}
