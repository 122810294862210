.article {
}

.article__media {
  position: relative;
}

.article__read-marker {
  position: absolute;
  top: -53px;
  right: -27px;
  width: 107px;
  height: 107px;
  background-color: $brand-secondary;
  border-radius: 500000px;
  color: $brand-white;
  font-size: 20px;
  font-weight: 700;
  -webkit-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);

  @include mq($until: mobile) {
    top: -40px;
    right: -27px;
    width: 80px;
    height: 80px;
  }

  @include mq(tablet) {
    top: -53px;
    right: -27px;
    width: 107px;
    height: 107px;
  }

  .theme-2 & {
    background-color: $brand-pink;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: block;
    padding: 10px;

    @include mq($until: mobile) {
      width: 80px;
      font-size: 16px;
    }

    @include mq(tablet) {
      width: 107px;
    }
  }
}

.article__share {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  color: $brand-primary;
  font-size: 16px;
  line-height: 37px;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 8px;
  }

  .at-share-btn  {
    .at-icon-wrapper {
      transition: transform 0.2s ease-in-out;
      margin: 7px 0 0 7px;
    }

    &:focus,
    &:hover {
      .at-icon-wrapper {
        transform: translateY(-4px);
      }
    }
  }

  // Email button no longer functions, force to be hidden
  .addthis_inline_share_toolbox .at-svc-email {
    display: none !important;
  }
}

.at-share-btn-elements {
  a {
    background: none !important;
    display: block !important;
  }
}

// ARTICLE TOP STYLES

.article-top {
  position: relative;
}

.article-top__inner {
  position: relative;
}

.article-top__bg-icon {
  position: absolute;
  top: 100px;
  right: 50px;

  @include mq($until: tablet-portrait) {
    display: none;
  }

  .icon-svg {
    opacity: 0.4;
    fill: $brand-grey-light;
    width: 437px;
    height: 630px;
  }
}
