.button-block {
  -webkit-box-shadow: 4px 4px 0px 0px rgba($brand-secondary, 1);
  -moz-box-shadow: 4px 4px 0px 0px rgba($brand-secondary, 1);
  box-shadow: 4px 4px 0px 0px rgba($brand-secondary, 1);

  .theme-2 & {
    -webkit-box-shadow: 4px 4px 0px 0px rgba($brand-pink, 1);
    -moz-box-shadow: 4px 4px 0px 0px rgba($brand-pink, 1);
    box-shadow: 4px 4px 0px 0px rgba($brand-pink, 1);
  }

  .content-area--neg & {
    @include mq($until: tablet-portrait) {
      transform: translateY(-70px);
    }

    @include mq(desktop-small) {
      transform: translateY(-120px);
    }
  }
}

.button-block-inner {
  display: flex;
  flex-wrap: wrap;
}

.button-block__btn {
  @include fade-up(0ms, 20px, self);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 120px;
  background-color: $brand-grey;
  border: 1px solid $brand-white;
  transition: background-color 0.3s;

  @include mq($from: tablet-portrait) {
    &:nth-child(2) {
      @include fade-up(100ms, 20px, self);
    }
  
    &:nth-child(3) {
      @include fade-up(200ms, 20px, self);
    }

    &:nth-child(4) {
      @include fade-up(300ms, 20px, self);
    }
    &:nth-child(5) {
      @include fade-up(400ms, 20px, self);
    }
    &:nth-child(6) {
      @include fade-up(500ms, 20px, self);
    }
  }

  

  @include mq($until: tablet-portrait) {
    flex: 1 0 50%;
    padding-top: 10px;
    justify-content: center;
    flex-direction: column;

    &:nth-child(2n) {
      @include fade-up(100ms, 20px, self);
    }
  }

  @include mq(desktop-small) {
    flex: 1 0 33%;
  }

  &:hover {
    background-color: $brand-primary;

    .button-block__txt-inner {
      color: $brand-white;
    }

    .button-block__icon {
      .icon-svg {
        fill: $brand-white;
      }
    }
  }
}

.button-block__txt {
  @include mq($until: tablet-portrait) {
    flex: 0 0 50%;
    text-align: center;
  }

  @include mq(desktop-small) {
    flex: 1 0 60%;
  }
}

.button-block__txt-inner {
  font-weight: 700;
  color: $brand-text;
  transition: color 0.3s;

  @include mq($until: tablet-portrait) {
    font-size: 18px;
    line-height: 24px;
  }

  @include mq(desktop-small) {
    font-size: 20px;
    line-height: 30px;
    padding: 10px 40px;
  }
}

.button-block__icon {
  @include mq($until: tablet-portrait) {
    flex: 1 0 auto;
    text-align: center;
  }

  @include mq(desktop-small) {
    flex: 1 0 auto;
  }

  .icon-svg {
    fill: $brand-primary;
    transition: fill 0.3s;
    margin-bottom: 0;

    @include mq($until: tablet-portrait) {
      width: 52px;
    }

    @include mq(desktop-small) {
      width: 75px;
    }
  }
}
