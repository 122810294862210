.btn--reset {
  @include helpers-reset-button;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
}

.btn {
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;

  + .btn {
    margin-left: 10px;
  }

  input[type='submit'] {
    border: none;
    background-color: transparent;
  }
}

.btn--primary {
  transition: background-color 0.3s;
  border: 1px solid $brand-primary;
  background-color: $brand-primary;

  &:hover {
    background-color: $brand-white;

    .btn__text {
      color: $brand-primary;
    }

    &.btn--outline {
      background-color: $brand-primary;

      .btn__text {
        color: $brand-white;
      }
    }

    &.btn--inverse {
      background-color: $brand-white;

      .btn__text {
        color: $brand-primary;
      }
    }
  }

  &.btn--outline {
    background-color: $brand-white;

    .btn__text {
      color: $brand-primary;
    }
  }

  .btn__text {
    transition: color 0.3s;
    color: $brand-white;

    .header__nav-link-object & {
      font-weight: 700;
    }
  }

  &.btn--inverse {
    border-color: $brand-white;
    background-color: $brand-primary;

    .btn__text {
      color: $brand-white;
    }
  }

  .header & {
    @include mq($until: tablet-portrait) {
      transition: all 0.3s;
      border: 1px solid $brand-white;
    }

    &.btn--outline {
      @include mq($until: tablet-portrait) {
        background-color: transparent;
      }

      .btn__text {
        @include mq($until: tablet-portrait) {
          transition: color 0.3s;
          color: $brand-white;
        }
      }
    }
  }

  .show-transparent-header & {
    @include mq(desktop-small) {
      transition: all 0.3s;
      border: 1px solid $brand-white;
    }

    &.btn--outline {
      @include mq(desktop-small) {
        background-color: transparent;
      }

      &:hover {
        @include mq(desktop-small) {
          background-color: $brand-white;
        }

        .btn__text {
          @include mq(desktop-small) {
            color: $brand-primary;
          }
        }
      }

      .btn__text {
        @include mq(desktop-small) {
          transition: color 0.3s;
          color: $brand-white;
        }
      }
    }
  }
}

.btn--secondary {
  transition: background-color 0.3s;
  border: 1px solid $brand-secondary;
  background-color: $brand-secondary;

  .theme-2 & {
    border: 1px solid $brand-pink;
    background-color: $brand-pink;
  }

  &:hover {
    background-color: $brand-white;

    .btn__text {
      color: $brand-secondary;

      .theme-2 & {
        color: $brand-pink;
      }
    }

    &.btn--outline {
      background-color: $brand-secondary;

      .theme-2 & {
        background-color: $brand-pink;
      }

      .btn__text {
        color: $brand-white;
      }
    }
  }

  &.btn--outline {
    background-color: $brand-white;

    .btn__text {
      color: $brand-secondary;

      .theme-2 & {
        color: $brand-pink;
      }
    }
  }

  .btn__text {
    transition: color 0.3s;
    color: $brand-white;

    .header__nav-link-object & {
      font-weight: 700;
      font-size: 18px;
    }
  }
}

.btn--tabs {
  transition: background-color 0.3s;
  border: 1px solid $brand-primary;
  background-color: $brand-white;

  &.tabs__active {
    background-color: $brand-primary;

    .btn__text {
      color: $brand-white;
    }
  }

  &:hover {
    background-color: $brand-primary;

    .btn__text {
      color: $brand-white;
    }

    &.tabs__active {
      background-color: $brand-white;

      .btn__text {
        color: $brand-primary;
      }
    }
  }

  .btn__text {
    transition: color 0.3s;
    color: $brand-primary;

    @include mq($until: mobile) {
      padding: 8px 10px;
    }
  }
}

.btn--filter {
  transition: background-color 0.3s;
  border-radius: 8px;
  background-color: $brand-grey;
  border: 1px solid $brand-grey;
  -webkit-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);

  @include mq($until: mobile) {
    min-width: 70px;
  }

  @include mq(tablet) {
    min-width: 90px;
  }

  &:hover {
    background-color: $brand-white;
  }

  &.btn--filter-reverse {
    color: $brand-white;
    background-color: $brand-primary;
    border: 1px solid $brand-primary;
  }
}

.btn__text {
  display: inline-block;
  padding: 8px 30px;
  line-height: 1;

  @include mq($until: mobile) {
    font-size: 16px;
  }

  @include mq(tablet) {
    font-size: 18px;
  }

  .btn--filter & {
    @include mq($until: mobile) {
      font-size: 12px;
      padding: 6px 10px;
    }

    @include mq(tablet) {
      font-size: 14px;
      padding: 6px 20px;
    }
  }
}

.btn-search {
  background: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    .icon-svg {
      fill: $brand-primary;

      .show-transparent-header & {
        fill: $brand-primary;
      }
    }
  }
  .icon-svg {
    width: 29px;
    height: 29px;
    fill: $brand-text;
    transition: fill 0.3s;

    @include mq(desktop-small) {
      fill: $brand-text;
    }

    .header & {
      @include mq($until: tablet-portrait) {
        fill: $brand-white;
      }
    }

    .show-transparent-header & {
      @include mq(desktop-small) {
        fill: $brand-white;
      }
    }
  }
}

.arrow-link {
  position: relative;
  display: block;
  padding: 10px 20px 10px 50px;
  font-weight: 700;
  color: $brand-primary !important;
  margin-bottom: 10px;

  .arrow-link__icon {
    position: absolute;
    left: 0;
    top: 6px;
    background-color: $brand-primary;
    border-radius: 500000px;
    width: 36px;
    height: 36px;
    display: inline-block;

    .icon-svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 18px;
      fill: $brand-white;
    }
  }
}

.filter-clear-btn {
  background: transparent;
  padding: 0;
  margin: 20px 0;
  border: none;
  float: right;
  color: $brand-primary;
  cursor: pointer;

  .icon-svg {
    margin-left: 5px;
    fill: $brand-primary;
    width: 16px;
    height: 16px;
  }
}
