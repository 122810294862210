.tabs {
  padding: 100px 0;
}

.tabs__holder {
}

.tabs__buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .btn {
    flex: 0 0 32%;
    max-width: 32%;
    margin: 0;
  }
}

.tabs__slide {
  display: none;

  &--active {
    display: block;
  }
}

.tabs__block {
  max-width: 560px;
  min-height: 378px;
  background-color: $brand-primary-light;

  font-size: 20px;
  line-height: 30px;
  color: $brand-primary;

  -webkit-box-shadow: 4px 4px 0px 0px rgba($brand-secondary, 1);
  -moz-box-shadow: 4px 4px 0px 0px rgba($brand-secondary, 1);
  box-shadow: 4px 4px 0px 0px rgba($brand-secondary, 1);

  .theme-2 & {
    -webkit-box-shadow: 4px 4px 0px 0px rgba($brand-pink, 1);
    -moz-box-shadow: 4px 4px 0px 0px rgba($brand-pink, 1);
    box-shadow: 4px 4px 0px 0px rgba($brand-pink, 1);
  }

  @include mq($until: tablet-portrait) {
    padding: 30px;
  }

  @include mq(desktop-small) {
    padding: 50px;
  }

  p {
    margin-bottom: 30px;
  }
}

.tabs__content {
  color: $brand-primary;

  @include mq($until: mobile) {
    padding: 20px 10px;
  }

  @include mq(tablet) {
    padding: 30px;
  }

  @include mq(desktop-small) {
    padding: 50px;
  }
}
