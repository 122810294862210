.radial-indicator {
  position: fixed;
  right: 20px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;

  @include mq($until: tablet-portrait) {
    display: none;
  }

  @include mq(desktop-small) {
    top: 90px;
  }

  @include mq(desktop) {
    top: 180px;
  }

  .icon-svg {
    transform: rotate(90deg);
    position: absolute;
    top: 28px;
    left: 26px;
    fill: $brand-primary;
    width: 30px;
    height: 26px;
  }
}
.radial-indicator :first-child {
  margin-bottom: 20px;
}
.progress {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.progress__meter,
.progress__value {
  fill: none;
}
.progress__meter {
  stroke: $brand-grey;
  fill: $brand-white;
}
.progress__value {
  stroke: $brand-secondary;
  stroke-linecap: round;

  .theme-2 & {
    stroke: $brand-pink;
  }
}
