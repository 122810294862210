.form-field {
  margin-bottom: 10px;
}

.field-container {
  margin-bottom: 10px;
  position: relative;

  select {
    font-family: $base-font-family;
    font-size: 16px;
    width: 100%;
    color: $brand-primary;
    border-radius: 3px;
    border: 1px solid rgba($brand-primary, 0.19);
    padding: 15px 30px 15px 30px;
    background-color: rgba($brand-grey, 0.19);
    background-image: url('~img/select.png');
    background-repeat: no-repeat;
    background-position: 98% center;
    -moz-appearance: window;
    -webkit-appearance: none;

    &:focus {
      outline: none;
      border: 1px solid $brand-primary;
      background-image: url('~img/select-active.png');
    }

    // &:active {
    //   background-image: url('~img/select-active.png');

    //   &:focus {
    //     background-image: url('~img/select-active.png');
    //   }
    // }

    &::-ms-expand {
      display: none;
    }
  }
}

.form__label,
.fake-label {
  display: inline-block;
  margin-bottom: 10px;
}

.control-label {
  @include font-weight(bold);
  display: inline-block;
  margin: 15px 0;
}

.field-container__input,
.field-container__textarea {
  border-radius: 3px;
  border: 1px solid rgba($brand-primary, 0.19);
  padding: 15px 30px 15px 30px;
  background-color: rgba($brand-grey, 0.19);
  font-family: $base-font-family;
  font-size: 16px;
  width: 100%;
  color: $brand-primary;

  &:focus {
    outline: none;
    border: 1px solid $brand-primary;
  }

  .is-not-valid & {
    border-color: $brand-red;
  }
}

.field-container__select {
  border: 2px solid $brand-black;
  width: 100%;
  padding: 10px 5px;
  font-family: $base-font-family;
  font-weight: 400;
  font-size: 16px;

  .is-not-valid & {
    border: 1px solid $brand-red;
  }
}

.field-container__group {
  margin-bottom: 5px;
}

.field-container input[type='radio'],
.field-container input[type='checkbox'] {
  height: 1px;
  width: 1px;
  visibility: hidden;
  text-indent: -100000px;
}

input[type='radio'] ~ .form__label,
input[type='checkbox'] ~ .form__label {
  padding: 5px 0 0 46px;
  position: relative;
  display: inline-block;
  width: auto;
  min-height: 30px;
}

input[type='checkbox'] ~ .form__label {
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    background-color: rgba($brand-grey, 0.19);
    border-radius: 3px;
    border: 1px solid rgba($brand-primary, 0.19);

    .is-not-valid & {
      border: 1px solid $brand-red;
    }
  }
}

input[type='checkbox']:checked ~ .form__label {
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    top: 7px;
    background-image: url('~img/svg/icon-tick.svg');
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
  }
}

input[type='radio'] ~ .form__label {
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-color: rgba($brand-grey, 0.19);
    border: 1px solid rgba($brand-primary, 0.19);
    border-radius: 10000px;

    .is-not-valid & {
      border: 1px solid $brand-red;
    }
  }
}

input[type='radio']:checked ~ .form__label {
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-color: $brand-primary;
    border-radius: 10000px;
  }
}

.field-container--has-date-button {
  .btn {
    display: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: 30px;
    width: 30px;
  }
}

.is-not-valid-msg {
  color: $brand-red;
  display: none;

  .is-not-valid & {
    display: block;
  }
}

.field-validation-error {
  color: $brand-red;
  padding: 0 0.625rem;
  margin-top: -1rem;
}
.input-validation-error {
  color: $brand-red;
  border: 1px solid rgba($brand-red, 0.25);
}

.form__row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @include mq(tablet) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .form__row__inner {
    flex: 1 0 100%;
  }

  .field-container {
    margin: 0;

    @include mq($until: mobile) {
      padding-bottom: 20px;
    }

    @include mq(tablet) {
      padding: 0 10px 20px;
    }
  }

  &--half {
    .form__row__inner {
      @include mq($until: mobile) {
        flex: 1 0 100%;
      }

      @include mq(tablet) {
        flex: 1 0 50%;
      }
    }
  }

  &--third {
    .form__row__inner {
      @include mq($until: mobile) {
        flex: 1 0 100%;
      }
      @include mq(tablet) {
        flex: 1 0 33%;
      }
    }
  }
}

.tabs__slide .control-label {
  display: none;
}

.input-validation-error {
  color: $brand-red;
  border: 1px solid rgba($brand-red, 0.25);
}

form input[type='submit'] {
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px solid $brand-primary;
  background-color: $brand-primary;
  transition: color 0.3s;
  color: $brand-white;
  font-weight: 700;
  padding: 0.5rem 1.875rem;
  font-family: $base-font-family;

  &:hover {
    background-color: $brand-white;
    color: $brand-primary;
  }
}
