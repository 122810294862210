* {
  box-sizing: border-box;
}

@keyframes ticker {
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.ticker-holder {
  @include fade-up(0ms, 20px, self);
  height: 400px;
  overflow: hidden;
  position: relative;

  @include mq(tablet) {
    height: 550px;
  }
}

.ticker__overlay-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.ticker__overlay {
  background-color: $brand-grey-light;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 550px;
  z-index: 1;
}

.ticker__logo {
  position: absolute;
  top: 60px;
  right: -2%;
  z-index: 2;

  .icon-svg {
    fill: rgba($brand-white, 0.8);
    height: 646px;
  }
}

.ticker-wrap {
  width: 100%;
  text-align: center;
  overflow: hidden;
  height: 140px;
  padding-top: 50px;
  padding-left: 0;
  box-sizing: content-box;
  position: relative;
  z-index: 1;

  @include mq(tablet) {
    height: 223px;
    line-height: 223px;
  }

  .ticker {
    display: inline-block;
    justify-content: center;
    height: 140px;
    line-height: 140px;
    white-space: nowrap;
    padding-right: 0;
    box-sizing: content-box;
    transform: translate3d(0, 0, 0);

    &.run-ticker {
      display: inline-block;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-name: ticker;

      .logo-card {
        height: 100%;
      }

      .logo-card__inner {
        height: 100%;
      }

      .logo-card__media-holder {
        height: 100%;
      }

      .logo-card__media-element {
        height: 100%;
      }
    }

    @include mq(tablet) {
      height: 223px;
      line-height: 223px;
    }

    &__item {
      display: inline-block;
      height: 140px;
      line-height: 140px;

      padding: 0 3rem;
      font-size: 3rem;
      color: white;

      img {
        max-width: none;
      }

      @include mq(tablet) {
        height: 223px;
        line-height: 223px;
      }

      .logo-card__media-element {
        max-height: 140px;

        @include mq(tablet) {
          max-height: 223px;
        }
      }
    }
  }
}

.ticker__base {
  position: relative;
  z-index: 1;
  text-align: center;
  width: 100%;
  top: 50px;
}
