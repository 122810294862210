.image-banner {
  position: relative;
  display: block;
  background-color: $brand-black;
  height: 250px;
  padding: 48px 0;

  @media (min-width: 768px) {
    height: 650px;
  }

  @supports (display: grid) {
    display: grid;
    height: auto;
    min-height: 250px;

    @media (min-width: 768px) {
      min-height: 650px;
    }
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      background: rgba($color: #000000, $alpha: 0.5);
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    img,
    video {
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover; object-position: center center;';
      width: 100%;
    }
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-width: 1640px + ($site-wide-padding * 2);
    margin: 0 auto;
    overflow: visible;
    padding: 0 $site-wide-padding;
    width: 100%;
    z-index: 2;
  }

  &__title {
    display: block;
    color: $brand-white;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

    @media (min-width: 768px) {
      font-size: 72px;
      line-height: 98px;
    }
  }

  &__content {
    display: block;
    color: $brand-white;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    margin: 12px 0 0;
    max-width: 1150px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

    @media (min-width: 768px) {
      font-size: 30px;
      font-weight: 600;
      line-height: 41px;
      margin: 32px 0 0;
    }
  }

  &--center {
    text-align: center;

    @supports (display: grid) {
      justify-content: center;
    }

    .image-banner__content {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.report-page {
  .image-banner {
    @include fade-up(0ms, 20px, self);    
  }

  .image-banner__title {
    @include fade-up(200ms, 20px, self);
  }

  .image-content {
    @include fade-up(300ms, 20px, self);
  }
}