.header-minimal {
  display: flex;
  align-items: center;
  background: #f4f7f9;
  flex-direction: row;
  height: 100px;

  @media (min-width: 768px) {
    height: 162px;
  }

  &__inner {
    display: block;
    max-width: 1640px + ($site-wide-padding * 2);
    margin: 0 auto;
    overflow: visible;
    padding-left: $site-wide-padding;
    padding-right: $site-wide-padding;
    width: 100%;
  }

  &__logo {
    display: inline-block;
    color: $brand-primary;

    > svg {
      display: block;
      height: 36px;
      width: 128px;

      @media (min-width: 768px) {
        height: 73px;
        width: 303px;
      }
    }
  }
}

// Currently should only be used on annual reports page.
.site-area--flush {
  margin-top: 0;
}
