.cookie {
  background-color: $brand-grey;
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  z-index: 19;
  padding: 10px 0;

  @include mq(tablet) {
    padding: 5px 0;
  }

  &.is-active {
    bottom: 0;
  }
}

.cookie__inner {
  display: flex;
  flex-wrap: wrap;
  font-family: $brand-font;
  font-size: 14px;

  @include mq(tablet) {
    justify-content: space-between;
  }

  a {
    color: $brand-primary;
    background-image: none;
    font-size: 14px;

    &:hover {
      background-image: none;
    }
  }

  span {
    flex: 1 0 100%;
    margin-bottom: 10px;

    @include mq(tablet) {
      flex: 1 0 auto;
      margin: 0;
    }
  }
}

.cookie__close-btn {
  background: none;
  border: none;
  text-decoration: none;
  font-family: $brand-font;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  padding: 0;
  color: $brand-primary;
}
