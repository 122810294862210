.nav-sticky {
  $fade-width: 60px;
  $scrollbar-width: 8px;

  position: sticky;
  top: 0;
  left: 0;
  display: block;
  background: #fff;
  flex-direction: row;
  width: 100%;
  z-index: 10;

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 1640px;
    margin: 0 auto;

    @media (min-width: 768px) {
      max-width: 1640px + ($site-wide-padding * 2);
      padding-left: $site-wide-padding;
      padding-right: $site-wide-padding;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%
      );
      height: calc(100% - #{$scrollbar-width});
      width: $fade-width;
    }

    ul {
      display: flex;
      flex-direction: row;
      height: 70px;
      list-style: none;
      margin: 0;
      padding: 0 $site-wide-padding 0 $site-wide-padding - 22px;

      @media (min-width: 768px) {
        height: 80px;
        padding: 0;
      }

      // Spacer to ensure last item is not hidden by fade
      &::after {
        content: '';
        display: block;
        min-height: 1px;
        min-width: $fade-width;
      }
    }

    li {
      display: block;
    }

    a {
      display: flex;
      align-items: center;
      color: $brand-black;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      height: 100%;
      justify-content: center;
      padding: 22px;
      transition: background-color 0.15s ease-out, color 0.15s ease-out;
      white-space: nowrap;

      &:focus {
        outline: none;
      }

      &:focus-within {
        background: $brand-primary;
        color: $brand-white;
      }

      &[aria-current],
      &:hover {
        background: $brand-primary;
        color: $brand-white;
      }
    }
  }

  &__scroll {
    overflow: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: $scrollbar-width;
      width: $scrollbar-width;
    }

    &::-webkit-scrollbar-track {
      background-color: $brand-primary-light;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $brand-primary;
    }
  }
}
