/**
 * Add typography styles in this file.
 */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0; // reset value from css normalize
  margin-bottom: 15px;
}

h1,
.h1 {
  font-weight: 700;

  @include mq($until: tablet-portrait) {
    font-size: 30px;
    line-height: 49px;
  }

  @include mq(desktop-small) {
    font-size: 50px;
    line-height: 71px;
  }
}

h2,
.h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 71px;

  @include mq($until: tablet-portrait) {
    font-size: 24px;
    line-height: 49px;
  }

  @include mq(desktop-small) {
    font-size: 40px;
    line-height: 71px;
  }
}

h3,
.h3 {
  font-weight: 700;

  @include mq($until: tablet-portrait) {
    font-size: 18px;
    line-height: 30px;
  }

  @include mq(desktop-small) {
    font-size: 24px;
    line-height: 38px;
  }
}

// h4,
// .h4 {
//   font-size: 24px;
//   font-weight: 700;
//   line-height: 38px;
// }

a {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: $brand-primary;
  text-decoration: none;

  // .theme-2 & {
  //   color: $brand-pink;
  // }
}

p {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.large-text {
  font-weight: 400;

  @include mq($until: tablet-portrait) {
    font-size: 18px;
    line-height: 30px;
  }

  @include mq(desktop-small) {
    font-size: 24px;
    line-height: 38px;
  }
}

.small-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

ul {
  li {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
}
