.search-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $brand-primary;
  display: none;
  z-index: 20;

  &.active {
    display: block;
  }
}

.search-modal__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.search-modal__close-btn {
  position: absolute;
  top: 30px;
  right: 50px;
  background-color: transparent;
  border: none;
  color: $brand-white;
  font-size: 20px;
  font-weight: 700;
  font-family: $brand-font;
  cursor: pointer;

  span {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    font-size: 50px;

    @include mq($until: tablet-portrait) {
      font-size: 30px;
    }

    @include mq(desktop-small) {
      font-size: 50px;
    }
  }
}

.search-modal__title {
  color: $brand-white;

  @include mq($until: tablet-portrait) {
    font-size: 18px;
    margin-bottom: 30px;
  }
}

.search-modal__search-holder {
  margin: 0 auto 50px;
  position: relative;
  width: 100%;
  min-width: 280px;

  @include mq($until: tablet-portrait) {
    max-width: 320px;
  }

  @include mq(desktop-small) {
    max-width: 600px;
  }

  .field-container__input {
    background-color: $brand-white;
    padding-right: 50px;
    line-height: 58px;

    @include mq($until: tablet-portrait) {
      padding-left: 10px;
      line-height: 38px;
    }

    @include mq(desktop-small) {
      line-height: 58px;
      min-height: 90px;
    }
  }
}

.search-modal__search-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
}

.search-modal__btn-block {
  @include mq($until: tablet-portrait) {
    display: none;
  }

  @include mq(desktop-small) {
    display: block;
    min-width: 900px;
  }

  @include mq(desktop) {
    min-width: 1180px;
  }
}

.search-modal__contact {
  margin: 100px 0;
  color: $brand-white;

  @include mq($until: tablet-portrait) {
    display: block;
  }

  @include mq(desktop-small) {
    display: none;
  }

  p {
    font-size: 14px;
  }
}
