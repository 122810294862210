.title-block {
  &--center {
    text-align: center;
  }
}

.title-block__title {
  &--primary {
    color: $brand-primary;
  }
}
