// sass-mq configuration - please see https://github.com/sass-mq/sass-mq for more infos
// Enable media queries support
$mq-responsive: true;

// Base font size
$mq-base-font-size: 16px !default;

// Defines breakpoints list
$mq-breakpoints: (
  mobile-small: 480px,
  // max
    mobile: 767px,
  // max
    tablet: 768px,
  tablet-portrait: 991px,
  desktop-small: 992px,
  desktop-small-max: 1239px,
  desktop: 1240px,
  desktop-lg: 1472px,
);

// Padding on either side of the main container.
$site-wide-padding: 30px;

// Width of the main container.
$desktop-width: map-get($mq-breakpoints, 'desktop');
$site-width: $desktop-width + ($site-wide-padding * 2);

@import '~sass-mq/mq';
