.modal {
  width: 0;
  height: 0;
  position: fixed;
  background-color: rgba($brand-primary, 0.9);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: $layer-root;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;

  .ktc-widget-body-wrapper & {
    width: 100%;
    height: 100vh;
    position: relative;
    top: auto;
    left: auto;
    visibility: visible;
    opacity: 1;
  }

  &.is-active {
    width: 100%;
    height: 100vh;
    z-index: $layer-god;
    visibility: visible;
    opacity: 1;
  }

  &__inner {
    width: 100%;
    max-width: 500px;
    min-height: 300px;
    background-color: $brand-white;
    padding: 50px 40px;
    position: relative;
    color: $brand-primary;

    @include mq(desktop-small) {
      min-width: 500px;
      width: auto;
      max-width: 650px;
    }

    .modal__close {
      position: absolute;
      top: 10px;
      right: 10px;
      color: $brand-primary;
      font-size: 15px;
      font-weight: 700;
      font-family: $brand-font;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        font-size: 25px;
        margin-left: 10px;
      }
    }
  }

  &__title {
    line-height: 30px;
    margin-bottom: 30px;

    @include mq(desktop-small) {
      line-height: 46px;
    }
  }

  &__center {
    margin-top: 30px;
    text-align: center;
  }
}
