.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  thead {
    th {
      padding: 10px;
    }
  }
  tbody {
    td {
      padding: 10px;
    }
  }
  // .rates {
  thead {
    background: $brand-teal;
    color: $brand-white;
  }
  tbody {
    background: $brand-grey;
    td {
      border: solid 1px $brand-white;
    }
  }
  // }
}
