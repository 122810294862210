.listing-area {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  @include mq($until: tablet-portrait) {
    padding: 0 30px;
  }

  @include mq($until: mobile) {
    padding: 0;
  }
}

.listing-area__row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  &:nth-child(odd) {
    .listing-area__block {
      &:nth-child(even) {
        @include mq(tablet) {
          margin-top: 100px;
        }
      }
    }
  }

  &:nth-child(even) {
    .listing-area__block {
      &:nth-child(odd) {
        @include mq(tablet) {
          margin-top: 100px;
        }
      }
    }
  }
}

.listing-area__block {
  @include mq($until: mobile) {
    margin-bottom: 40px;
    flex: 1 0 100%;
    text-align: center;
  }

  @include mq(tablet) {
    margin-bottom: 80px;
    flex: 1 0 50%;
  }

  &:nth-child(even) {
    @include mq(tablet) {
      text-align: right;
    }
  }

  .card {
    display: inline-block;
    @include mq($until: tablet-portrait) {
      width: 300px;
    }

    @include mq(desktop-small) {
      width: 434px;
    }
  }
}
