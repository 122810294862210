.brand-colour-box {
  margin: 10px;
  display: inline-block;
}

.brand-colour-box__top {
  margin-bottom: 10px;
}

.brand-colour-box__bottom {
  width: 160px;
  height: 160px;

  &.brand-primary {
    background-color: $brand-primary;
  }

  &.brand-secondary {
    background-color: $brand-secondary;
  }

  &.brand-grey {
    background-color: $brand-grey;
  }

  &.brand-dark-grey {
    background-color: $brand-grey-dark;
  }

  &.brand-medium-grey {
    background-color: $brand-grey-medium;
  }

  &.brand-light-grey {
    background-color: $brand-grey-light;
  }

  &.brand-pink {
    background-color: $brand-pink;
  }

  &.brand-orange {
    background-color: $brand-orange;
  }

  &.brand-mid-purple {
    background-color: $brand-mid-purple;
  }

  &.brand-gradient-1 {
    @include gradient-generator($brand-gradient-1a, $brand-gradient-1b);
  }
  &.brand-gradient-2 {
    @include gradient-generator($brand-gradient-2a, $brand-gradient-2b);
  }
  &.brand-gradient-3 {
    @include gradient-generator($brand-gradient-3a, $brand-gradient-3b);
  }
  // &.brand-gradient-4 {
  //     @include gradient-generator($brand-gradient-4a, $brand-gradient-4b);
  // }
  // &.brand-gradient-5 {
  //     @include gradient-generator($brand-gradient-5a, $brand-gradient-5b);
  // }
}

.fake-content {
  min-height: 140vh;
  background-color: $brand-grey-dark;
}
