.content-area {
  position: relative;
  padding-bottom: 50px;

  @include mq($until: tablet-portrait) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @include mq(desktop-small) {
    padding-top: 70px;
    padding-bottom: 50px;
  }

  &--neg {
    padding-top: 0;
  }

  &--grey {
    background-color: $brand-grey;
  }
}

.content-area__shil {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 0;
  opacity: 0.3;

  .icon-svg {
    fill: white;
    width: 450px;
    height: 619px;
  }
}

.content-area__wysiwyg {
  position: relative;

  @include mq($until: tablet-portrait) {
    margin: 10px 0 20px;
  }

  @include mq(desktop-small) {
    margin: 30px 0 50px;
  }

  .grid-column {
    @include mq($until: tablet-portrait) {
      padding-bottom: 10px;
    }

    &:first-child {
      @include fade-up(200ms, 20px, self);
    }

    &:last-child {
      @include fade-up(300ms, 20px, self);
    }
  }
}
