.video-card {
  @include fade-up(200ms, 20px, self);
  
  @include mq($until: tablet-portrait) {
    margin-bottom: 40px;
  }

  @include mq(desktop-small) {
    margin-bottom: 50px;
  }
}

.video-card__header {
  @include fade-up(0ms, 20px, self);
  text-align: center;
  max-width: 900px;

  @include mq($until: tablet-portrait) {
    margin: 40px auto;
  }

  @include mq(desktop-small) {
    margin: 50px auto;
  }
}

.video-card__title {
  color: $brand-primary;

  @include mq($until: tablet-portrait) {
    font-size: 32px;
  }

  @include mq(desktop-small) {
    font-size: 40px;
  }
}

.video-card__media {
  position: relative;
  cursor: pointer;
}

.video-card__background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include mq($until: tablet-portrait) {
    height: 400px;
  }

  @include mq(desktop-small) {
    height: 600px;
  }
}

.video-card__media-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 8px $brand-white solid;
  border-radius: 500000px;
  background-color: $brand-white;

  .icon-svg {
    fill: $brand-primary;
    width: 100px;
    height: 100px;
  }
}

.basicLightbox {
  &:after {
    content: 'X';
    position: absolute;
    top: 10px;
    right: 5px;
    width: 40px;
    height: 40px;
    font-family: $brand-font;
    font-size: 30px;
    color: $brand-white;
    text-align: center;
    cursor: pointer;
  }
}
