@keyframes slide-background {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-8%);
  }
}

@keyframes arrow-bob {
  0% {
    transform: translateX(0);
  }
  66% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(-20px);
  }
  74% {
    transform: translateX(0);
  }
  77% {
    transform: translateX(-18px);
  }
  80% {
    transform: translateX(0);
  }
  83% {
    transform: translateX(-15px);
  }
  86% {
    transform: translateX(0);
  }
  88% {
    transform: translateX(-12px);
  }
  91% {
    transform: translateX(0);
  }
  95% {
    transform: translateX(-10px);
  }
  98% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

.tns-liveregion {
  display: none;
}

.home-carousel {
  @include fade-up(0ms, 20px, self);
  position: relative;

  .tns-nav {
    position: absolute;
    bottom: 100px;
    left: 100px;
    z-index: 1;
    display: flex;
    flex-direction: column;

    @include mq($until: mobile) {
      display: none;
    }

    @include mq(desktop-small) {
      bottom: 150px;
    }

    button {
      position: relative;
      width: 10px;
      height: 10px;
      background-color: $brand-white;
      border: none;
      border-radius: 500000px;
      padding: 0;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      &:focus {
        outline: none;
      }

      &.tns-nav-active {
        background-color: $brand-secondary;

        .theme-2 & {
          background-color: $brand-pink;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 48px;
          height: 48px;
          border: 1px solid $brand-white;
          border-radius: 500000px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.home-carousel__slide {
  position: relative;
  overflow: hidden;
}

.home-carousel__media {
  height: calc(100vh - 50px);
  width: 110%;
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;

  @include mq($until: mobile) {
    background-position: center;
  }

  .tns-slide-active & {
    animation-name: slide-background;
    animation-duration: 12s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.home-carousel__overlay {
  z-index: 1;

  @include mq($until: mobile) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($brand-black, 0.2);
  }
}

.home-carousel__content {
  position: absolute;
  z-index: 2;

  @include mq($until: mobile) {
    width: 100%;
    padding: 0 15px;
    bottom: 80px;
    left: 0;
  }

  @include mq(tablet) {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.home-carousel__title {
  color: $brand-white;
  display: block;
  background-color: $brand-primary;
  position: relative;

  @include mq($until: mobile) {
    padding: 0 8px;
    width: 100%;
  }

  @include mq(tablet) {
    max-width: 700px;
    padding: 20px 50px 20px 0;
  }

  &:before {
    @include mq(tablet) {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      height: 110%;
      width: 80px;
      display: block;
      background-color: $brand-primary;
    }
  }

  .home-carousel__content & {
    margin-bottom: 0;
  }
}

.home-carousel__intro {
  @include mq($until: mobile) {
    display: none;
  }

  @include mq(tablet) {
    margin-top: -10px;
    max-width: 650px;
    padding: 0 50px 30px;
    color: $brand-white;
    display: block;
    background-color: $brand-primary;
  }
}

.home-carousel__down-arrow {
  @include mq($until: mobile) {
    display: none;
  }

  @include mq(tablet) {
    position: absolute;
    bottom: 100px;
    right: 100px;
    z-index: 1;
    background: transparent;
    border: none;
    transform: rotate(90deg);
    cursor: pointer;
  }

  @include mq(desktop-small) {
    bottom: 150px;
  }

  .icon-svg {
    width: 64px;
    height: 44px;
    fill: $brand-white;
    animation-name: arrow-bob;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }
}
