.gallery-carousel {
  position: relative;

  img {
    width: 100%;
  }

  .gallery-nav {
    .card-carousel__previous {
      left: -50px;
    }
    .card-carousel__next {
      right: -50px;
    }
  }
}
