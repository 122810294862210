.card {
  margin-top: 60px;
  margin-bottom: 40px;
  width: 434px;

  @include mq(desktop-small) {
    display: inline-block;
  }

  .listing-area__block & {
    @include mq($until: tablet-portrait) {
      max-width: 300px;
      width: 100%;
    }
  }

  &--cta,
  &--product,
  &--bio {
    margin-top: 0;
    width: 100%;
    -webkit-box-shadow: 4px 4px 0px 0px rgba($brand-secondary, 1);
    -moz-box-shadow: 4px 4px 0px 0px rgba($brand-secondary, 1);
    box-shadow: 4px 4px 0px 0px rgba($brand-secondary, 1);
    flex: 1 1 100%;
    height: -webkit-fill-available;

    .theme-2 & {
      -webkit-box-shadow: 4px 4px 0px 0px rgba($brand-pink, 1);
      -moz-box-shadow: 4px 4px 0px 0px rgba($brand-pink, 1);
      box-shadow: 4px 4px 0px 0px rgba($brand-pink, 1);
    }
  }

  &.card--cta {
    @include fade-up(0ms, 20px, self);
  }
  
  &--product{
    @include mq(tablet) {
      height: calc(100% - 40px);
    }
  }

  &--article {
    margin-top: 0;
    width: 100%;
    max-width: 390px;
  }

  .card-carousel__slide & {
    margin: auto auto 20px;
  }

  .card__content {
    margin-bottom: 30px;
  }

  .content-area & {
    position: relative;
  }
}

.card--cta {
  .card__content {
    @include fade-up(300ms, 20px, self);
  }
}

.card__inner {
  max-width: none;
  padding: 0;
  position: relative;
  margin-bottom: 40px;

  &:hover {
    .card__hover-btn {
      opacity: 1;
    }
  }

  .card--article & {
    margin-bottom: 10px;
    -webkit-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
  }

  .card--cta & {
    margin-bottom: 0;

    @include mq(tablet) {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
    }
  }

  .card--product &,
  .card--bio & {
    background-color: $brand-white;
    overflow: hidden;
    margin-bottom: 0;
    height: 100%;

    @include mq(tablet) {
      padding: 170px 30px 20px;
    }
  }
}

.card__top {
  height: fit-content;
  position: relative;
  -webkit-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);

  .card--product &,
  .card--bio & {
    box-shadow: none;
    position: absolute;
    top: -30px;
    left: -30px;
  }

  .card--article & {
    box-shadow: none;
  }
}

.card__icon-holder {
  position: relative;
  background-color: $brand-primary;
  border-radius: 5000000px;
  transform-origin: 50% 50%;
  transition: transform 0.3s;

  @include mq(tablet) {
    height: 180px;
    width: 180px;
  }

  .card--bio & {
    overflow: hidden;
  }

  .card--product a:hover & {
    transform: scale(1.06);

    .icon-svg {
      transform: translate(-50%, -50%) scale(0.75);
    }
  }

  .icon-svg {
    transition: transform 0.3s;
    fill: $brand-white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75px;

    @include mq(tablet) {
      width: 100px;
    }
  }

  img {
    @include object-fit();
    width: 180px;
    height: 180px;
    object-position: 50% 50%;
    display: block;
  }
}

.card__media-holder {
  width: 100%;

  @include mq($until: mobile) {
    height: 300px;
  }

  @include mq(tablet) {
    width: 434px;
    height: 434px;
  }

  .card__media {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    background: transparent;
  }
}

.card__media {
  width: 100%;
  background-image: url('~img/card-placeholder.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @include mq($until: tablet) {
    height: calc(100vw - 100px);
  }

  @include mq(tablet) {
    width: 434px;
    height: 434px;
  }

  .listing-area__block & {
    @include mq($until: tablet-portrait) {
      max-width: 300px;
      height: 300px;
      width: 100%;
    }
  }

  .card--cta & {
    @include mq(tablet) {
      min-height: 434px;
      height: 100%;
      width: 100%;
    }
  }

  .card--article & {
    height: 264px;
    width: 100%;
  }
}

.card__base {
  position: absolute;
  left: 0;
  width: 85%;
  z-index: 2;

  @include mq($until: tablet-portrait) {
    bottom: 20px;
  }

  @include mq(desktop-small) {
    bottom: 40px;
  }

  .card--article &,
  .card--product &,
  .card--bio & {
    position: relative;
    left: auto;
    bottom: auto;
    width: 100%;
  }

  .card--product &,
  .card--bio & {
    color: $brand-text;

    @include mq(tablet) {
      position: relative;
      top: auto;
      left: auto;
      transform: translateY(0);
    }
  }

  .card--article & {
    padding: 12px;
  }
}

.card__base-content {
  color: $brand-white;
  display: block;
  background-color: $brand-primary;
  margin-bottom: 0;
  text-align: left;

  @include mq($until: tablet-portrait) {
    padding: 10px 20px;
  }

  @include mq(desktop-small) {
    padding: 10px 40px;
  }

  .card--article & {
    color: $brand-text;
    background-color: transparent;
    // padding-left: 0;
    text-align: center;
  }
}

.card__product-desc {
  @include mq($until: desktop-small-max) {
    font-size: 16px;
  }
}

.card__left {
  flex-grow: 1;
  @include mq(tablet) {
    flex: 1 0 40%;
  }
}

.card__right {
  flex-grow: 1;
  background-color: $brand-grey;

  @include mq(tablet) {
    flex: 1 0 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.card__right-inner {
  @include mq(tablet) {
    padding: 30px 60px;
  }
}

.card__title {
  .card--cta & {
    @include fade-up(200ms, 20px, self);

    @include mq(tablet) {
      font-size: 46px;
      line-height: 71px;
    }
  }

  .card--product &,
  .card--bio & {
    transition: color 0.3s;
  }

  .card--product a:hover &,
  .card--bio a:hover & {
    color: $brand-secondary;
  }
}

.card__recommended {
  position: absolute;
  top: -50px;
  right: -50px;
  width: 107px;
  height: 107px;
  color: $brand-white;
  background-color: $brand-secondary;
  border-radius: 5000000px;
  -webkit-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
  z-index: 2;
  display: none;

  @include mq($until: tablet-portrait) {
    right: -20px;
  }

  &--active {
    display: block;
  }

  .theme-2 & {
    background-color: $brand-pink;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
  }
}

.card__hover-btn {
  transition: opacity 0.3s;
  position: absolute;
  background-color: $brand-primary;
  border-radius: 5000000px;
  -webkit-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);

  @include mq($until: tablet-portrait) {
    right: -20px;
    width: 60px;
    height: 60px;
  }

  @include mq(desktop-small) {
    right: -30px;
    width: 80px;
    height: 80px;
  }

  .icon-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    fill: $brand-white;
    stroke: $brand-white;
  }
}

.card__tag-area {
  text-align: left;
}

@include mq($until: mobile) {
  .card {
    margin: 0;
    .card__inner {
      padding: 0;
      .card__top {
        position: unset;
        min-height: 135px;
        .card__icon-holder {
          position: unset;
          height: 150px;
          width: 150px;
          transform: translate(-32px, -32px);
        }
      }
    }
    &--product {
      .card__inner {
        display: flex;
        .card__base {
          align-self: center;
          p {
            display: none;
          }
        }
      }
    }
    &--cta {
      .card__inner {
        .card__right {
          .card__right-inner {
            padding: 20px 40px;
          }
        }
        .card__left {
          .card__media {
            // same value with *mobile*
            max-width: 747px;
            min-height: 200px;
          }
        }
      }
    }
  }
  .card-carousel__slide {
    .card {
      max-width: 17.5rem;
      .card__inner,
      .card__media {
        padding: 0;
        height: 300px;
        width: 300px;
      }
    }
  }
}
