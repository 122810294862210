.help-support {
  width: 100%;
  margin: 50px 0;
}

.help-support__header {
  display: flex;
}

.help-support__heading {
  background-color: $brand-primary;
  border-right: 3px solid $brand-white;
  position: relative;

  @include mq($until: tablet-portrait) {
    flex: 0 0 auto;
  }

  @include mq(desktop-small) {
    flex: 0 0 28%;
  }

  &.help-support__heading--mob-active {
    @include mq($until: tablet-portrait) {
      cursor: pointer;
    }
  }

  &--active {
    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-top-color: $brand-secondary;
      border-width: 20px;
      margin-left: -20px;

      .theme-2 & {
        border-top-color: $brand-pink;
      }
    }

    @include mq($until: tablet-portrait) {
      flex: 1 0 20%;
    }
  }

  &:last-child {
    border-right: none;

    @include mq($until: tablet-portrait) {
      flex: 0 0 auto;
    }

    @include mq(desktop-small) {
      flex: 1 0 28%;
    }

    &.help-support__heading--mob-active {
      @include mq($until: tablet-portrait) {
        flex: 1 0 auto;
      }
    }
  }
}

.help-support__heading-inner {
  @include mq($until: tablet-portrait) {
    padding: 20px;
  }

  @include mq(desktop-small) {
    padding: 20px 40px;
  }
}

.help-support__heading-title {
  color: $brand-white;
  margin: 0;

  @include mq($until: tablet-portrait) {
    font-size: 24px;
    opacity: 0.6;
  }

  @include mq(desktop-small) {
    font-size: 38px;
  }

  .help-support__heading--mob-active & {
    @include mq($until: tablet-portrait) {
      opacity: 1;
    }
  }

  span {
    position: relative;

    @include mq($until: tablet-portrait) {
      display: none;
      font-size: 16px;
    }

    @include mq(desktop-small) {
      display: inline-block;
      font-size: 20px;
      top: -3px;
    }

    .help-support__heading--active & {
      display: inline-block;
    }
  }
}

.help-support__content {
  display: flex;
  min-height: 600px;
  background-color: $brand-grey;

  li {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
  }
}

.help-support__section {
  background-color: $brand-grey;
  height: 600px;

  @include mq($until: tablet-portrait) {
    flex: 0 0 auto;
  }

  @include mq(desktop-small) {
    border-right: 3px solid $brand-secondary;
    flex: 0 0 28%;
  }

  .theme-2 & {
    @include mq(desktop-small) {
      border-right: 3px solid $brand-pink;
    }
  }

  &:last-child {
    border-right: none;

    // @include mq ( $until: tablet-portrait ) {
    //     flex: 0 0 auto;
    // }

    @include mq(desktop-small) {
      flex: 1 0 28%;
    }
  }
}

.help-support__section-inner {
  padding: 30px;
  display: none;

  &.section-active {
    display: block;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }
}

.help-support__topics {
  position: relative;

  @include mq($until: tablet-portrait) {
    display: none;
  }

  &--active {
    @include mq($until: tablet-portrait) {
      display: block;
      flex: 1 0 100%;
    }
  }

  li {
    margin-bottom: 5px;
    cursor: pointer;

    div {
      position: relative;
      display: block;
      padding: 12px 20px 12px 80px;
      color: $brand-text;
      font-weight: 700;
      border-radius: 3px;
    }

    &.topic--active {
      div {
        background-color: $brand-primary;
        color: $brand-white;
      }

      .help-support__link-icon {
        background-color: $brand-white;
      }

      .icon-svg {
        fill: $brand-primary;
        stroke: $brand-primary;
      }
    }
  }
}

.help-support__pointer {
  display: none;

  @include mq(desktop-small) {
    position: absolute;
    top: 200px;
    left: 110%;
  }

  .help-support__topics--active & {
    @include mq($until: tablet-portrait) {
      display: none;
    }
    @include mq(desktop-small) {
      display: block;
    }
  }
}

.help-support__pointer-inner {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  background-color: $brand-primary;
  color: $brand-white;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 40px 10px 20px;

  &:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-right-color: $brand-secondary;
    border-width: 15px;
    margin-top: -15px;

    .theme-2 & {
      border-right-color: $brand-pink;
    }
  }
}

.help-support__link-icon {
  position: absolute;
  left: 20px;
  top: 8px;
  background-color: $brand-primary;
  border-radius: 500000px;
  width: 36px;
  height: 36px;
  display: inline-block;

  .icon-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 18px;
    fill: $brand-white;
    stroke: $brand-white;
  }
}

.help-support__categories {
  display: none;

  &--active {
    display: block;

    @include mq($until: tablet-portrait) {
      flex: 1 0 100%;
    }
  }

  li {
    margin-bottom: 5px;
    cursor: pointer;

    div {
      position: relative;
      display: block;
      padding: 12px 20px 12px 40px;
      color: $brand-text;
      font-weight: 700;
      border-radius: 3px;
    }

    &.category--active {
      div {
        background-color: $brand-primary;
        color: $brand-white;
      }

      .help-support__link-icon {
        background-color: $brand-white;
      }

      .icon-svg {
        fill: $brand-primary;
        stroke: $brand-primary;
      }
    }
  }
}

.help-support__content-box {
  display: block;
  padding: 20px 40px;
  background-color: $brand-primary-light;
  margin-bottom: 20px;

  &.help-support__content-box--links {
    a {
      display: block;
      padding-left: 50px;
      margin-bottom: 20px;

      &:before {
        content: '';
        position: absolute;
        width: 36px;
        height: 36px;
        top: -4px;
        left: 0;

        border-radius: 500000px;
        background-color: $brand-primary;
        display: inline-block;
      }

      &:after {
        content: '';
        background-image: url('~img/svg/icon-arrow-white.svg');
        position: absolute;
        top: 4px;
        left: 8px;
        // transform: translate(-50%, -50%);
        width: 20px;
        height: 18px;
        color: $brand-white;
      }
    }
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $brand-text !important;
    font-weight: 700;
    position: relative;
  }
}

.help-support__options {
  display: none;

  &--active {
    display: block;

    @include mq($until: tablet-portrait) {
      flex: 1 0 100%;
    }
  }
}

.help-support__option-inner {
  display: none;
  padding: 30px;

  &.option--active {
    display: block;
  }
}
