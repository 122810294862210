.mob-menu-btn {
  font-family: $brand-font;
  color: $brand-white;
  font-size: 14px;
  font-weight: 700;
  background-color: $brand-primary;
  border: none;
  text-align: center;
  padding: 10px 7px 5px;
  border-radius: 3px;
  cursor: pointer;

  @include mq(desktop) {
    display: none;
  }

  .show-transparent-header & {
    background-color: $brand-white;
    color: $brand-primary;
  }
}

.mob-menu-btn__lines {
  @include helpers-burger(23px, 1px, 6px, $brand-white);
  margin: 10px auto;
  z-index: 1;

  .show-transparent-header & {
    @include helpers-burger(23px, 1px, 6px, $brand-primary);
  }
}
