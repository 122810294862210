/**
 * Add brand variables in this file.
 */

$brand-black: #000000;
$brand-white: #ffffff;
$brand-red: #ff0000;
$brand-green: #39b54a;

$brand-primary: #74489d;
$brand-primary-light: #e6deed;
$brand-secondary: #44c8f5;

$brand-pink: #d8137e;
$brand-orange: #e9832c;
$brand-mid-purple: #9f5b95;
$brand-teal: #005a7f;

// Gradient 1
$brand-gradient-1a: $brand-pink;
$brand-gradient-1b: $brand-orange;

// Gradient 2
$brand-gradient-2a: $brand-primary;
$brand-gradient-2b: $brand-mid-purple;

// Gradient 3
$brand-gradient-3a: $brand-primary;
$brand-gradient-3b: $brand-pink;

$brand-grey: #ebebeb;
$brand-grey-light: #efefef;
$brand-grey-medium: #86878c;
$brand-grey-dark: #4f5055;

$brand-text: $brand-grey-dark;
