.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $brand-white;
  border-bottom: 1px solid $brand-grey;
  transition: all 0.3s;
  z-index: 10;

  @include mq($until: desktop-small-max) {
    border-top: 3px solid $brand-grey;
    padding: 10px 20px;
  }

  @include mq(desktop) {
    padding: 70px 20px 25px;
  }

  &.edit-mode {
    position: relative;
  }

  &.show-transparent-header {
    // @include mq ( desktop-small ) {
    background-color: rgba($brand-white, 0);
    border-bottom: 1px solid rgba($brand-grey, 0);
    // }
  }
}

.header__inner {
  display: flex;
}

.header__logo-holder {
  @include mq($until: desktop-small-max) {
    flex: 1 0 200px;
  }

  @include mq(desktop) {
    flex: 0 0 250px;
  }

  .icon-svg,
  svg {
    fill: $brand-primary;
    transition: fill 0.3s;

    @include mq($until: desktop-small-max) {
      width: 167px;
      height: 50px;
    }

    @include mq(desktop) {
      width: 234px;
      height: 65px;
    }

    .show-transparent-header & {
      // @include mq ( desktop-small ) {
      fill: $brand-white;
      // }
    }
  }
}

.header__nav-holder {
  @include mq($until: desktop-small-max) {
    position: fixed;
    top: 0;
    left: 110%;
    width: 100%;
    height: 100vh;
    background-color: $brand-primary;
    transition: left 0.3s;

    z-index: 10;
  }

  @include mq(desktop) {
    flex: 1 0 auto;
    justify-content: flex-end;
    align-self: center;
  }

  &.active-nav {
    @include mq($until: desktop-small-max) {
      left: 0;
    }
  }
}

.header__top {
  @include mq($until: desktop-small-max) {
    color: $brand-white;
  }

  @include mq(desktop) {
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    background-color: $brand-grey;
    padding: 12px 20px;
  }
}

.header__top-inner {
  @include mq($until: desktop-small-max) {
    padding: 10px 20px;
  }

  @include mq(desktop) {
    text-align: right;
  }
}

.header__top-links {
  padding: 0;
  margin: 0;

  @include mq($until: desktop-small-max) {
    display: flex;
    justify-content: space-between;
  }

  li {
    display: inline-block;

    @include mq($until: desktop-small-max) {
      margin-left: 4px;
      margin-right: 4px;
    }

    @include mq(desktop) {
      margin-right: 20px;
    }

    &:last-child {
      @include mq(desktop) {
        margin-right: 0;
      }
    }
  }

  a {
    display: flex;
    align-items: center;

    @include mq($until: desktop-small-max) {
      color: $brand-white !important;
    }

    @include mq(desktop) {
      color: $brand-text !important;
    }
  }
}

.header__nav {
  @include mq($until: desktop-small-max) {
    padding: 80px 60px 10px;
  }

  @include mq(desktop) {
    text-align: right;
  }
}

.header__nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header__nav-link-object {
  line-height: 0;

  @include mq($until: desktop-small-max) {
    display: block;
    padding: 15px 0;
  }

  @include mq(desktop) {
    display: inline-block;
    vertical-align: middle;
    margin-right: 25px;
  }

  &:last-child {
    @include mq(desktop) {
      margin-right: 0;
    }
  }

  a {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    transition: color 0.3s;

    @include mq($until: desktop-small-max) {
      color: $brand-white;
    }

    @include mq(desktop) {
      color: $brand-text;
    }

    &:hover,
    &.is-active {
      @include mq($until: desktop-small-max) {
        color: $brand-white;
      }

      @include mq(desktop) {
        color: $brand-primary;
      }
    }

    .show-transparent-header & {
      // @include mq ( desktop-small ) {
      color: $brand-white;
      // }

      &:hover {
        @include mq(desktop) {
          color: $brand-primary;
        }
      }
    }
  }
}

.header__nav-link-holder {
  @include mq($until: desktop-small-max) {
    position: absolute;
    top: 80px;
    left: 110%;
    width: 100%;
    height: calc(100vh - 80px);
    background-color: $brand-primary;
    padding: 0 60px;
    transition: left 0.3s;
    z-index: 1;
  }

  @include mq(desktop) {
    position: relative;
  }

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 100%;
    width: 100%;
    height: 30px;
    background-color: transparent;
  }

  &:hover {
    .header__sub-nav {
      display: block;
    }
  }

  &.active-sub-nav {
    @include mq($until: desktop-small-max) {
      left: 0;
    }
  }
}

.header__mob-sub-btn {
  cursor: pointer;

  @include mq($until: desktop-small-max) {
    background: transparent;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    color: $brand-white;
    display: block;
    border: none;
    padding: 0 30px 0 0;
    width: 100%;
    text-align: left;
    position: relative;
  }

  @include mq(desktop) {
    display: none;
  }

  .icon-svg {
    @include mq($until: desktop-small-max) {
      position: absolute;
      top: 0;
      right: 0;
      fill: $brand-white;
      width: 8px;
      height: 16px;
    }
  }
}

.header__sub-nav {
  list-style: none;
  margin: 0;
  text-align: left;

  @include mq($until: desktop-small-max) {
    padding-left: 0;
  }

  @include mq(desktop) {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 280px;
    padding: 20px;
    background-color: $brand-white;
    border-radius: 3px;
    display: none;
    -webkit-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.16);
  }

  &:after {
    @include mq(desktop) {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $brand-secondary;
      border-width: 15px;
      margin-left: -15px;
    }

    .theme-2 & {
      @include mq(desktop) {
        border-bottom-color: $brand-pink;
      }
    }
  }

  li {
    padding: 5px 0;
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
  }

  a {
    line-height: 22px;
    font-size: 16px;

    @include mq($until: desktop-small-max) {
      font-weight: 400;
      color: $brand-white;
    }

    @include mq(desktop) {
      font-weight: 700;
      color: $brand-text;
    }

    .show-transparent-header & {
      @include mq($until: desktop-small-max) {
        color: $brand-white;
      }

      @include mq(desktop) {
        color: $brand-text;
      }
    }

    &:hover {
      .show-transparent-header & {
        @include mq(desktop) {
          color: $brand-primary;
        }
      }
    }
  }
}

.header__nav-home-btn {
  @include mq($until: desktop-small-max) {
    color: $brand-white;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    display: block;
  }

  @include mq(desktop) {
    display: none;
  }
}

.header__nav-close-btn {
  cursor: pointer;

  @include mq($until: desktop-small-max) {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    font-family: $brand-font;
    color: $brand-white;
    font-size: 14px;
    font-weight: 700;
  }

  @include mq(desktop) {
    display: none;
  }

  span {
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    display: block;
  }
}

.header__nav-back-btn {
  cursor: pointer;

  @include mq($until: desktop-small-max) {
    background-color: transparent;
    border: none;
    margin-bottom: 20px;
    padding: 0;
  }

  @include mq(desktop) {
    display: none;
  }

  .icon-svg {
    @include mq($until: desktop-small-max) {
      fill: $brand-white;
      width: 25px;
      height: 21px;
      transform: rotate(180deg);
    }
  }
}

.header__nav-area-title {
  @include mq($until: desktop-small-max) {
    display: block;
    margin-bottom: 20px;
  }
}

// GOOGLE TRANSLATE STYLES

#google_translate_element {
  display: inline;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

// Overwriting styles from Google
.goog-te-gadget {
  font-size: inherit !important;
  color: inherit;
  font-family: inherit !important;

  a {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    img {
      display: none !important;
    }

    span {
      border-left: none !important;
    }
  }
}

.goog-te-gadget-simple {
  background: none !important;
  border: none !important;
  padding: 0;
}

.goog-te-menu-value {
  &:after {
    content: '';
    background-image: url('~img/select.png');
    height: 6px;
    margin-left: 8px;
    width: 11px;
  }

  span {
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.25rem !important;
    font-family: $base-font-family !important;
    display: none !important;

    &:first-child {
      display: inline-block !important;
    }
  }

  img {
    display: none !important;
  }
}
.goog-te-gadget-icon {
  display: none !important;
}
