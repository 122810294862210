.grid-color-debug {
  .grid-column,
  .site-wide,
  .site-wide--responsive-padding {
    background-color: rgba(blue, 0.4);
  }
}

/**
 * Add grid layouts in this partial.
 *
 */

// Site-wide is used as a container for the grid or components
.site-wide {
  max-width: $site-width;
  margin: 0 auto;
  padding-left: $site-wide-padding;
  padding-right: $site-wide-padding;
  overflow: visible;
}

.site-wide-large {
  max-width: 1500px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  overflow: visible;
}

.grid-container {
  @supports not (display: grid) {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 auto;
    flex-direction: row;
    margin-left: -25px;
    margin-right: -25px;
    padding-left: 0;
    padding-right: 0;
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 100%;
    margin-left: 0;
    margin-right: 0;
    grid-column-gap: 40px;
  }

  > .grid-column {
    padding-bottom: 30px;
    width: 100%;

    @supports not (display: grid) {
      flex: 0 0 auto;
      flex-direction: column;
      display: block;
      box-sizing: border-box;
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding-left: 25px;
      padding-right: 25px;
      // height: 100%;
    }

    @supports (display: grid) {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
      
      .grid-column--product{
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.grid-container--full {
  @supports (display: grid) {
    grid-template-columns: 1fr;
  }
}

.grid-container--full-central {
  @include mq(tablet) {
    @supports (display: grid) {
      grid-template-columns: 75%;
      justify-content: center;
    }
  }

  > .grid-column {
    @include mq(tablet) {
      @supports not (display: grid) {
        flex-basis: 75%;
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
      }

      @supports (display: grid) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.grid-container--full-central-narrow {
  @include mq(tablet) {
    @supports (display: grid) {
      grid-template-columns: 50%;
      justify-content: center;
    }
  }

  > .grid-column {
    @include mq(tablet) {
      @supports not (display: grid) {
        flex-basis: 50%;
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;
      }

      @supports (display: grid) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

// Grid 1:1
.grid-container--half {
  @include mq(tablet) {
    @supports (display: grid) {
      grid-template-columns: 1fr 1fr;
    }
  }

  > .grid-column {
    @supports not (display: grid) {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
}

.grid-container--side-content {
  @include mq(desktop-small) {
    @supports (display: grid) {
      grid-template-columns: 21fr 19fr;
    }
  }

  > .grid-column {
    @supports not (display: grid) {
      flex-basis: 48%;
      max-width: 48%;
    }

    &:first-child {
      @supports not (display: grid) {
        flex-basis: 52%;
        max-width: 52%;
      }

      .inner-page__holder & {
        @include mq(desktop-small) {
          margin-top: 100px;
        }
      }
    }

    &:last-child {
      @include mq(desktop-small) {
        text-align: right;
      }
    }
  }
}

// Grid 1:1:1
.grid-container--third {
  @include mq(tablet) {
    @supports (display: grid) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  > .grid-column {
    @supports not (display: grid) {
      flex-basis: 33.33%;
      max-width: 33.33%;
    }
  }
}

// Grid 1:1:1:1
.grid-container--quarter {
  @include mq(tablet) {
    @supports (display: grid) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  > .grid-column {
    @supports not (display: grid) {
      flex-basis: 25%;
      max-width: 25%;
    }
  }
}

.grid-container--two-third-left {
  @include mq(tablet) {
    @supports (display: grid) {
      grid-template-columns: 2fr 1fr;
    }
  }

  > .grid-column {
    @supports not (display: grid) {
      flex-basis: 33.33%;
      max-width: 33.33%;
    }

    &:first-child {
      @supports not (display: grid) {
        flex-basis: 66.66%;
        max-width: 66.66%;
      }
    }
  }
}

.grid-container--two-third-right {
  @include mq(tablet) {
    @supports (display: grid) {
      grid-template-columns: 1fr 2fr;
    }
  }

  > .grid-column {
    @supports not (display: grid) {
      flex-basis: 33.33%;
      max-width: 33.33%;
    }

    &:last-child {
      @supports not (display: grid) {
        flex-basis: 66.66%;
        max-width: 66.66%;
      }
    }
  }
}

.grid-container--one-quarter-right {
  @include mq(tablet) {
    @supports (display: grid) {
      grid-template-columns: 1fr 3fr;
    }
  }

  > .grid-column {
    @supports not (display: grid) {
      flex-basis: 75%;
      max-width: 75%;
    }

    &:first-child {
      @include mq($until: mobile) {
        order: 2;
      }
      @supports not (display: grid) {
        flex-basis: 25%;
        max-width: 25%;
      }
    }
  }
}

.grid-container--one-quarter-left {
  @include mq(tablet) {
    @supports (display: grid) {
      grid-template-columns: 3fr 1fr;
    }
  }

  > .grid-column {
    @supports not (display: grid) {
      flex-basis: 75%;
      max-width: 75%;
    }

    &:last-child {
      @supports not (display: grid) {
        flex-basis: 25%;
        max-width: 25%;
      }
    }
  }
}

.grid-container--no-gutter {
  @supports (display: grid) {
    grid-column-gap: 0;
  }

  @supports not (display: grid) {
    margin-left: 0;
    margin-right: 0;
  }

  // @include mq ( $until: mobile ) {
  //   margin-left: -30px;
  //   margin-right: -30px;
  // }

  .grid-column {
    padding-bottom: 0;

    @supports not (display: grid) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}


.report-page {

  .grid-container {   

    > .grid-column {
      @include fade-up(0ms, 20px, self);
    }

    @include mq(tablet) {
      &.grid-container--half {
        > .grid-column {
          &:first-child {
            @include fade-up(0ms, 20px, self);
          }
          &:last-child {
            @include fade-up(100ms, 20px, self);
          }
        }
      }
    
      &.grid-container--third {
        > .grid-column {
          &:nth-child(1) {
            @include fade-up(0ms, 20px, self);
          }
          &:nth-child(2) {
            @include fade-up(100ms, 20px, self);
          }
          &:nth-child(3) {
            @include fade-up(200ms, 20px, self);
          }
        }
      }
    }
  }
}