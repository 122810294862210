.page-listing__carousel {
  @include fade-up(0ms, 20px, self);
  position: relative;
  margin: 80px 0 120px;

  .tns-nav {
    position: absolute;
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%);

    button {
      padding: 0;
      width: 12px;
      height: 12px;
      border-radius: 5000000px;
      background-color: $brand-grey-medium;
      border: none;
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }

      &:focus {
        outline: none;
      }

      &.tns-nav-active {
        background-color: $brand-secondary;

        .theme-2 & {
          background-color: $brand-pink;
        }
      }
    }
  }
}

.page-listing__title {
  text-align: center;
  padding: 0 30px 40px;
}

.page-listing__slide {
  text-align: center;

  .card {
    margin: auto;
  }
}
