.inner-banner {
  height: 535px;
  position: relative;

  @include mq($until: tablet-portrait) {
    margin-bottom: 80px;
  }

  @include mq(desktop-small) {
    margin-bottom: 120px;
  }
}

.inner-banner__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &.lazy-load.loaded {
    opacity: 0.6;
  }
}

.inner-banner__overlay-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.inner-banner__overlay {
  background-color: $brand-primary-light;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.inner-banner__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  z-index: 2;

  @include mq($until: mobile) {
    display: none;
  }

  .icon-svg {
    fill: $brand-white;
    width: 600px;
    height: 846px;
  }
}

.inner-banner__content-area {
  position: relative;
  z-index: 3;

  @include mq($until: tablet-portrait) {
    top: 30px;
  }

  @include mq(desktop-small) {
    bottom: -250px;
  }
}

.inner-banner__box {
  background-color: $brand-primary;

  @include mq($until: mobile) {
    padding: 30px;
    height: 560px;
  }

  @include mq(tablet) {
    padding: 40px 30px;
    height: 420px;
  }

  @include mq(desktop) {
    padding: 40px 80px;
  }
}

.inner-banner__btn {
  display: inline-block;
  border-bottom: 3px solid $brand-secondary;
  padding-bottom: 10px;
  padding-right: 30px;
  margin-bottom: 20px;

  .theme-2 & {
    border-bottom: 3px solid $brand-pink;
  }

  .icon-svg {
    display: inline-block;
    vertical-align: middle;
    transform: rotate(180deg);
    stroke: $brand-white;
    width: 18px;
    height: 15px;

    .theme-2 & {
      fill: $brand-pink;
      stroke: $brand-pink;
    }
  }
}

.inner-banner__btn-txt {
  display: inline-block;
  vertical-align: middle;
  color: $brand-white;
  font-size: 16px;
  line-height: 1;
  padding-left: 10px;
}

.inner-banner__title {
  font-size: 36px;
  color: $brand-white;

  @include mq($until: desktop-small-max) {
    line-height: 40px;
  }

  @include mq(desktop) {
    margin-bottom: 11px;
    line-height: 45px;
  }
}

.inner-banner__content {
  color: $brand-white;

  @include mq($until: desktop-small-max) {
    font-size: 24px;
    line-height: 32px;
  }

  @include mq(desktop) {
    font-size: 28px;
    line-height: 40px;
  }
}
