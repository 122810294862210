.accordion {
  margin: 50px 0;
}

.accordion__title {
  color: $brand-primary;
  text-align: left;
  margin-bottom: 20px;
}

.accordion__object {
  background-color: $brand-grey;
  margin-bottom: 10px;
}

.accordion__top {
  position: relative;
  background-color: $brand-primary;

  cursor: pointer;

  @include mq($until: tablet-portrait) {
    padding: 15px 70px 15px 30px;
  }

  @include mq(desktop-small) {
    padding: 20px 70px;
  }

  &.is-active {
    .icon-svg {
      transform: translateY(-50%) rotate(-90deg);
    }
  }

  .icon-svg {
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%) rotate(90deg);
    fill: $brand-white;
    width: 14px;
    height: 26px;
  }
}

.accordion__object-title {
  color: $brand-white;
  font-size: 24px;
  line-height: 30px;

  @include mq($until: tablet-portrait) {
    font-size: 18px;
    line-height: 24px;
  }

  @include mq(desktop-small) {
    font-size: 24px;
    line-height: 30px;
  }
}

.accordion__body {
  display: none;

  &.is-active {
    display: block;
  }
}

.accordion__body-inner {
  padding: 50px 70px;

  @include mq($until: tablet-portrait) {
    padding: 20px 30px;
    font-size: 18px;
    line-height: 24px;
  }

  @include mq(desktop-small) {
    padding: 50px 70px;
    font-size: 20px;
    line-height: 30px;
  }

  p {
    @include mq($until: tablet-portrait) {
      font-size: 18px;
    }
  }
}
