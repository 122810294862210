.coloured-card {
  flex: 1 0 33%;

  @include mq($until: tablet-portrait) {
    height: 300px;
  }

  @include mq(desktop-small) {
    height: 472px;
  }

  @include mq(desktop) {
    height: 572px;
  }
}

.coloured-card__inner {
  position: relative;
  padding: 50px;
  height: 100%;

  @include mq($until: tablet-portrait) {
    padding: 10px 20px;
  }

  @include mq(desktop-small) {
    padding: 50px 30px;
  }

  @include mq(desktop) {
    padding: 50px;
  }
}

.coloured-card__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: 0 bottom;

  @include mq($until: tablet-portrait) {
    height: 300px;
  }

  @include mq(desktop-small) {
    height: 472px;
  }

  @include mq(desktop) {
    height: 572px;
  }
}

.coloured-card__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($brand-primary, 0.8);

  .coloured-card--secondary & {
    background-color: rgba($brand-secondary, 0.8);

    .theme-2 & {
      background-color: rgba($brand-pink, 0.8);
    }
  }
}

.coloured-card__content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
}

.coloured-card__title {
  color: $brand-white;
  display: block;

  @include mq($until: tablet-portrait) {
    height: 150px;
    line-height: 30px;
  }

  @include mq(desktop-small) {
    font-size: 36px;
    line-height: 40px;
    height: 180px;
  }

  @include mq(desktop) {
    font-size: 46px;
    line-height: 50px;
    height: 220px;
  }
}
