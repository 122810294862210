$easing-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);

@mixin animation-loop-delay($items, $delay: 100ms) {
  @for $i from 1 to $items + 1 {
    &:nth-child(#{$i}) {
      animation-delay: $i * $delay;
    }
  }
}

@mixin fade-up($delay: 0s, $amount: 20px, $mode: self) {
  @supports (display: grid) {
    opacity: 0;
    transform: translateY($amount);
    animation: fade-up 600ms $easing-cubic forwards $delay;
    animation-delay: 0s;
    animation-play-state: paused;
    will-change: opacity, transform;

    @include mq(tablet) {
      animation-delay: $delay;
    }

    @if ($mode == parent) {
      .animated & {
        animation-play-state: running;
      }
    } @else if ($mode == self) {
      &.animated {
        animation-play-state: running;
      }
    }

    @keyframes fade-up {
      0% {
        opacity: 0;
        transform: translateY($amount);
      }
      60% {
        opacity: 1;
        transform: translateY(-6px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }
}

.ktc-editable-area [data-anim='animated'] {
  opacity: 1 !important;
  animation: none !important;
  transform: translateY(0px) !important;
}

@mixin animation-reset {
  opacity: 1 !important;
  animation: none !important;
  transform: translateY(0px) !important;
}

@mixin image-fade-up($amount: 60px, $mode: self) {
  @supports (display: grid) {
    opacity: 0;
    filter: grayscale(1);
    transform: translateY($amount);
    transition: opacity 600ms $easing-cubic, filter 600ms $easing-cubic 600ms,
      transform 600ms $easing-cubic;
    will-change: filter, opacity, transform;

    @if ($mode == parent) {
      .animated & {
        opacity: 1;
        filter: grayscale(0);
        transform: translateY(0px);
      }
    } @else if ($mode == self) {
      &.animated {
        opacity: 1;
        filter: grayscale(0);
        transform: translateY(0px);
      }
    }
  }

  .ktc-editable-area & {
    opacity: 1 !important;
    animation: none !important;
    transform: translateY(0px) !important;
    filter: grayscale(0) !important;
  }
}

@mixin image-fade-left($amount: 60px, $mode: self) {
  @supports (display: grid) {
    opacity: 0;
    filter: grayscale(1);
    transform: translateX($amount);
    transition: opacity 600ms $easing-cubic, filter 600ms $easing-cubic 600ms,
      transform 600ms $easing-cubic;
    will-change: filter, opacity, transform;

    @if ($mode == parent) {
      .animated & {
        opacity: 1;
        filter: grayscale(0);
        transform: translateX(0px);
      }
    } @else if ($mode == self) {
      &.animated {
        opacity: 1;
        filter: grayscale(0);
        transform: translateX(0px);
      }
    }
  }

  .ktc-editable-area & {
    opacity: 1 !important;
    animation: none !important;
    filter: grayscale(0) !important;
    transform: translateX(0px) !important;
  }
}

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  * {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}
