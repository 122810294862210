.card-carousel {
  @include fade-up(0ms, 20px, self);
  position: relative;

  @include mq($until: tablet-portrait) {
    margin: 30px 20px;
  }

  @include mq(desktop-small) {
    margin: 50px 0 100px;
  }

  .tns-ovh {
    padding-top: 50px;
  }
}

.card-carousel__slide {
  &:nth-child(even) {
    @include mq(desktop-small) {
      padding-top: 70px;
    }
  }
}

.card-carousel__base {
  padding: 80px 30px 0;
  text-align: center;

  .btn {
    @include mq($until: mobile) {
      width: 100%;
      margin-left: 0;
      margin-bottom: 15px;
    }
  }
}

.card-carousel__nav {
  @include mq($until: tablet-portrait) {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 0;
  }
}

.card-carousel__previous {
  background: transparent;
  border: none;

  @include mq(desktop-small) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: calc(100% - 70px);
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  .icon-svg {
    transform: rotate(180deg);
    fill: $brand-primary;

    @include mq($until: tablet-portrait) {
      width: 22px;
      height: 42px;
    }

    @include mq(desktop-small) {
      width: 43px;
      height: 82px;
    }
  }
}

.card-carousel__next {
  background: transparent;
  border: none;

  @include mq(desktop-small) {
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    height: calc(100% - 70px);
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  .icon-svg {
    fill: $brand-primary;
    @include mq($until: tablet-portrait) {
      width: 22px;
      height: 42px;
    }
    @include mq(desktop-small) {
      width: 43px;
      height: 82px;
    }
  }
}
