.footer__social-bar {
  background-color: $brand-primary;
  padding: 10px 0;
  border-bottom: 1px solid $brand-white;
}

.footer__social-bar-inner {
  display: flex;
}

.footer__social-bar-btn {
  flex: 1 0 auto;
  text-align: center;
  color: $brand-white !important;

  span {
    vertical-align: middle;
    padding-top: 2px;
    padding-left: 10px;

    @include mq($until: mobile) {
      font-size: 14px;
      line-height: 14px;
      display: none;
    }

    @include mq(tablet) {
      display: inline-block;
      font-size: 18px;
      line-height: 18px;
    }
  }

  .icon-svg {
    fill: $brand-white;
    vertical-align: middle;
  }

  .icon-fb {
    width: 12px;
    height: 23px;
  }

  .icon-tw {
    width: 26px;
    height: 21px;
  }
}

.footer__main {
  background-color: $brand-grey-dark;

  @include mq($until: mobile) {
    padding: 70px 50px 50px;
  }

  @include mq(tablet) {
    padding: 70px 0 50px;
  }
}

.footer__logo {
  .icon-svg {
    fill: $brand-white;

    @include mq($until: mobile) {
      width: 195px;
    }

    @include mq(tablet) {
      width: 233px;
      height: 65px;
    }
  }
}

.footer__title {
  color: $brand-white;
  font-size: 20px;

  @include mq($until: mobile) {
    line-height: 20px;
  }

  a {
    color: $brand-white;
  }
}

.footer__links {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  a {
    color: $brand-white !important;

    @include mq($until: mobile) {
      font-size: 14px;
      line-height: 20px;
    }

    @include mq(tablet) {
      font-size: 18px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer__base {
  padding: 35px 0;
}

.footer__base-links {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: inline-block;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    color: $brand-text !important;
  }
}
