.links-card {
  margin-bottom: 40px;
  background-color: $brand-grey-light;
  width: 100%;
  max-width: 434px;
  text-align: left;
  position: relative;

  -webkit-box-shadow: 4px 4px 0px 0px rgba($brand-secondary, 1);
  -moz-box-shadow: 4px 4px 0px 0px rgba($brand-secondary, 1);
  box-shadow: 4px 4px 0px 0px rgba($brand-secondary, 1);

  .theme-2 & {
    -webkit-box-shadow: 4px 4px 0px 0px rgba($brand-pink, 1);
    -moz-box-shadow: 4px 4px 0px 0px rgba($brand-pink, 1);
    box-shadow: 4px 4px 0px 0px rgba($brand-pink, 1);
  }

  @include mq($until: tablet-portrait) {
    display: block;
  }

  @include mq(desktop-small) {
    display: inline-block;
  }

  &--primary {
    background-color: $brand-primary-light;
  }
}

.links-card__inner {
  @include mq($until: mobile) {
    padding: 30px;
  }

  @include mq(tablet) {
    padding: 50px;
  }

  a {
    display: block;
    color: $brand-text !important;
    font-weight: 700;
    position: relative;
    padding-left: 50px;
    margin-bottom: 20px;

    &:before {
      content: '';
      position: absolute;
      width: 36px;
      height: 36px;
      top: -4px;
      left: 0;

      border-radius: 500000px;
      background-color: $brand-primary;
      display: inline-block;
    }

    &:after {
      content: '';
      background-image: url('~img/svg/icon-arrow-white.svg');
      position: absolute;
      top: 4px;
      left: 8px;
      // transform: translate(-50%, -50%);
      width: 20px;
      height: 18px;
    }
  }
}

.links-card__title {
  color: $brand-primary;

  @include mq($until: tablet-portrait) {
    font-size: 24px;
  }
}

.links-card__link {
  display: block;
  color: $brand-text !important;
  font-weight: 700;
  position: relative;
  padding-left: 50px;
  margin-bottom: 20px;
}

.links-card__arrow {
  .icon-svg {
  }
}
