/* TypeAhead Overrides */
.twitter-typeahead {
  position: inherit !important;
  display: inherit !important;
}

.tt-menu {
  width: 100%;
  color: $brand-primary;
}

.tt-dataset {
  background: $brand-white;
  padding: 10px;
  border-radius: 0 0 3px 3px;
}

.search-result {
  background: $brand-white;
  text-align: left;

  &:hover {
    background: lighten($brand-primary, 10%);
  }
}

.search-result__title {
  color: $brand-primary;
  font-size: 18px;

  .tt-suggestion & {
    font-weight: 400;
  }
}
